<template>
  <div
    class="main-login-container page__login"
    :class="!isAsComponent ? 'page__login_route-container': ''"
  >
    <MainPage
      v-if="isHideMainPop"
      ref="mainPage"
      :is-hide-main-pop="isHideMainPop"
      :lang-text="langText"
      @BindEmail="showBindEmail"
    />
    <SDrawer
      v-else-if="isAsComponent"
      ref="aside"
      :no-header="true"
      class="login-pop-drawer"
      :show-close="false"
      :type="'full'"
      :visible="showIndex"
      :direction="'btt'"
      :immediately-render="true"
    >
      <MainPage
        ref="mainPage"
        data-customSceneName="dialog_login"
        :class="{ 'not-fsp-element': popConfig.activelyPopLogin }"
        :lang-text="langText"
        :pop-config="popConfig"
        @BindEmail="showBindEmail"
      />
    </SDrawer>
    <LoginCommonDialogs
      ref="loginCommonDialogsRef"
    />
    <!--loading-->
    <SLazyMount mount-prop-name="show">
      <s-loading
        type="curpage"
        :show="isShowLoading"
        :append-to-body="true"  
      />
    </SLazyMount>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { mainPageMixin } from './mixin/index'
import '@login/common/common_init.js'

export default defineComponent({
  name: 'LoginIndex',
  components: { SLazyMount, SDrawer, SLoading },
  mixins: [ mainPageMixin ]
})
</script>

import { createStore as _createStore } from 'vuex'
import createState from './state'
import createMutations from './mutations'
import actions from './actions'


export default function createStore() {
  const loginModule = {
    namespaced: true,
    state: createState(),
    mutations: createMutations(),
    actions
  }
  return _createStore({
    modules: {
      login: loginModule
    }
  })
}

import { createApp as _createApp } from 'vue'
import Index from './index.vue'
import creteStore from './store'
import './analysis/index'
import { markPoint } from 'public/src/services/mark/index.js'

function createApp () {
  const store = creteStore()
  
  if (!window.loginInstanceNumbers) window.loginInstanceNumbers = 0

  const div = document.createElement('div') // 登录弹窗HTML
  const elClass = `j-login-page-${window.loginInstanceNumbers}`
  div.setAttribute('class', elClass)
  document.body.appendChild(div)

  window.loginInstanceNumbers++

  const PreloadAbt = window.LoginPageAbt || window.SHEIN_LOGIN?.__pre_abt || {}
  const PreloadData = window.LoginPreloadData || window.SHEIN_LOGIN?.__pre_data || {}
  store.commit('login/initSsrData', { LoginLangText: window.loginLanguage, LoginPreloadData: PreloadData })
  store.dispatch('login/setPrefetchData', { PreloadAbt, PreloadData })
  store.commit('login/setnewUIStates', { showType: 'continue', isToLogin: false, isSwitch: false })
  markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })


  const login = _createApp(Index)
  login.config.globalProperties.$evt = window._GB_LoginEventCenter_
  login.use(store)
  const rootComponent = login.mount(`.${elClass}`)
  // 为了更新privacyStatus状态，在欧洲地区IP访问us站点的时候需要更新countryId来控制隐私政策的显示隐藏
  window._GB_LoginEventCenter_?.on('us-privacy-ip-login', (data) => {
    login['countryId'] = data
  })
  
  return rootComponent
}

export default createApp
